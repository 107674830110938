<template>
  <section class="search-results-header">
    <section class="search-results-summary">
      <p class="result">
        <strong>{{ result.total }}件</strong>の検索結果中{{ result.from.toLocaleString() }}件目～{{
          result.to.toLocaleString()
        }}件目
      </p>
      <p class="order">（{{ order.text }}に表示）</p>
      <!-- <template v-if="0 < searchTime && searchTime < 2000">
        ({{ (searchTime / 1000).toFixed(3) }} <small>秒</small>)
      </template> -->
    </section>

    <section class="seach-results-condition" :class="sizeClass">
      <p v-if="searchQuery.title" class="condition-item">
        <span class="label">タイトル</span>
        <span class="condition" @click="showAdvanceSearch">
          <span class="current">{{ searchQuery.title }}</span>
          <v-btn icon class="removeIcon" @click.stop="removeTitleCondition"><v-icon>mdi-close</v-icon></v-btn>
        </span>
      </p>
      <p v-if="searchQuery.author" class="condition-item">
        <span class="label">著者・編者等</span>
        <span class="condition" @click="showAdvanceSearch">
          <span class="current">{{ searchQuery.author }}</span>
          <v-btn icon class="removeIcon" @click.stop="removeAuthorCondition"><v-icon>mdi-close</v-icon></v-btn>
        </span>
      </p>
      <p v-if="searchQuery.publisher" class="condition-item">
        <span class="label">発行元</span>
        <span class="condition" @click="showAdvanceSearch">
          <span class="current">{{ searchQuery.publisher.join('、') }}</span>
          <v-btn icon class="removeIcon" @click.stop="removePublisherCondition"><v-icon>mdi-close</v-icon></v-btn>
        </span>
      </p>
      <p v-if="searchQuery.publishedOn" class="condition-item">
        <span class="label">発行年月日</span>
        <span class="condition" @click="showAdvanceSearch">
          <span class="current">{{ searchQuery.publishedOn.gte }}〜{{ searchQuery.publishedOn.lte }}</span>
          <v-btn icon class="removeIcon" @click.stop="removePublishedOnCondition"><v-icon>mdi-close</v-icon></v-btn>
        </span>
      </p>
      <!-- <p class="condition-item" v-if="searchQuery.practiceArea">
        <span class="label">領域</span>
        <span class="condition" @click="showAdvanceSearch">
          <span class="current">{{ searchQuery.practiceArea }}</span>
          <v-btn icon class="removeIcon" @click.stop="removePracticeAreaCondition"><v-icon>mdi-close</v-icon></v-btn>
        </span>
      </p>
      <p class="condition-item" v-if="searchQuery.tagId">
        <span class="label">タグ</span>
        <span class="condition" @click="showAdvanceSearch">
          <span class="current">{{ searchQuery.tagId.join('、') }}</span>
          <v-btn icon class="removeIcon" @click.stop="removeTagCondition"><v-icon>mdi-close</v-icon></v-btn>
        </span>
      </p> -->
    </section>
  </section>
</template>

<script lang="ts">
import { Component, Prop, State, Vue } from 'nuxt-property-decorator';
import { ResultStatus } from '@/pages/search';
import { State as MyState } from '@/store';
import { generateSearchRoute, getPathFromSearchLocation } from '@/utils/routeUtils';
import { Order } from '@/constants';
import * as Constants from '@/constants';
import { PartialSearchQuery } from '@/types/SearchQuery';

@Component
export default class SearchResultsHeader extends Vue {
  /** 幅の指定。 'narrow' の場合はラベルが非表示になる */
  @Prop() size!: 'full' | 'narrow';

  /** 検索結果情報 */
  @Prop() result!: ResultStatus;

  /** ソート順 */
  @Prop() order!: Order;

  /** 検索クエリの検索情報 */
  @State((state: MyState) => state.search.query) searchQuery!: PartialSearchQuery;

  /** 詳細検索ダイアログが表示されているかどうかのフラグ */
  showAdvanceSearch() {
    this.$store.commit('showAdvancedSearch');
  }

  get sizeClass(): string {
    return `-${this.size}`;
  }

  /** 検索実行 */
  private postSearch(query: PartialSearchQuery, targetBlank: boolean) {
    const to = generateSearchRoute(query, Constants.Search.option);
    if (targetBlank) {
      window.open(getPathFromSearchLocation(to));
      return;
    }

    this.$router.push(to);
  }

  isMetaPressed(event: KeyboardEvent | MouseEvent): boolean {
    return event && (event.ctrlKey || event.metaKey);
  }

  removeTitleCondition(event: KeyboardEvent | MouseEvent): void {
    const targetBlank = this.isMetaPressed(event);
    this.postSearch({ ...this.searchQuery, title: '' }, targetBlank);
  }

  removeAuthorCondition(event: KeyboardEvent | MouseEvent): void {
    const targetBlank = this.isMetaPressed(event);
    this.postSearch({ ...this.searchQuery, author: '' }, targetBlank);
  }

  removePublisherCondition(event: KeyboardEvent | MouseEvent): void {
    const targetBlank = this.isMetaPressed(event);
    this.postSearch({ ...this.searchQuery, publisher: [] }, targetBlank);
  }

  removePublishedOnCondition(event: KeyboardEvent | MouseEvent): void {
    const targetBlank = this.isMetaPressed(event);
    this.postSearch({ ...this.searchQuery, publishedOn: {} }, targetBlank);
  }
}
</script>

<style lang="scss" scoped>
.search-results-header {
  margin: 20px 0;
}

.search-results-summary {
  margin-bottom: 10px;
  font-size: 14px;

  > .result {
    display: inline-block;
    margin: 0;
  }

  > .order {
    display: inline-block;
    margin: 0;
  }
}

.seach-results-condition {
  display: flex;
  flex-wrap: wrap;

  > .condition-item {
    display: flex;
    align-items: center;
    margin: 6px 16px 6px 0;

    &:last-child {
      margin-right: 0;
    }

    > .label {
      color: #8f8f8f;
      font-size: 14px;
      margin-right: 8px;
      white-space: nowrap;
    }

    > .condition {
      display: flex;
      align-items: center;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      white-space: nowrap;
      cursor: pointer;
      padding: 0 2px;

      &:hover {
        background: #e8eff9;
      }

      > .current {
        padding: 2px 0 2px 6px;
        font-size: 12px;
        color: $primary;
        max-width: 70vw;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .removeIcon {
        margin: 0 2px;
        color: $primary;

        // Vuetify のスタイルを上書き
        height: 21px;
        width: 21px;
        padding-top: 1px; // 閉じるボタンの縦位置微調整

        .v-icon {
          font-size: 14px !important;
          height: 14px !important;
          width: 14px !important;
        }
        // Vuetify のスタイルを上書き
      }
    }
  }

  &.-narrow {
    > .condition-item {
      > .label {
        display: none;
      }

      > .condition {
        max-width: 40vw;
      }
    }
  }
}
</style>
