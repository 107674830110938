import { z } from 'zod';
/**
 * dateStringが現在日時を超過していたらtrue、そうでなければfalseを返す
 * dateStringがISO 8601形式でない場合はfalse
 * @param dateString
 */
const dateStringSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/);

export const isDateAfterNow = (dateString: string | undefined): boolean => {
  if (!dateString) {
    return false;
  }

  if (!dateStringSchema.safeParse(dateString).success) {
    return false;
  }

  const date = new Date(dateString);
  return date.getTime() >= Date.now();
};
