<template>
  <section class="kommentar-knowledge-panel">
    <h1 class="title">「会社法コンメンタール」（商事法務）をLegalscapeでご利用いただけます。</h1>
    <p class="lead">
      全文を解析し、リンクや逆引き検索など、コンメンタールの持つポテンシャルを最大限に活かす機能を実現。紙の書籍とは異なる新しいリサーチ体験を実現します。
    </p>
    <section class="wrapper">
      <div class="image">
        <img :src="'https://static.legalscape.jp/thumbnail/kommentar_shojihomu_kaishaho_1.png'" />
      </div>
      <div class="content">
        <div class="kan-wrapper">
          <nuxt-link v-for="kommentar in kommentarVolumes" :key="kommentar.path" :to="kommentar.path" class="kan">
            <span class="kan-title">
              <span>
                <span class="volume">{{ kommentar.volume }}<small>巻</small></span>
                {{ kommentar.title }}
              </span>
              <span v-if="kommentar.startArticle && kommentar.endArticle" class="line"></span>
            </span>
            <span class="kan-subject">
              <template v-if="kommentar.startArticle && kommentar.endArticle">
                {{ kommentar.startArticle }}〜{{ kommentar.endArticle }}条
              </template>
            </span>
          </nuxt-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';

type Volume = {
  volume: string;
  startArticle?: string;
  endArticle?: string;
  title: string;
  path: string;
  authors: string[];
  publishedAt: string;
};

@Component
export default class Search extends Vue {
  get kommentarVolumes(): Volume[] {
    return [
      {
        volume: '1',
        startArticle: '1',
        endArticle: '31',
        title: '総則・設立 ⑴',
        path: '/document/kommentar_shojihomu_kaishaho_1',
        authors: ['江頭 憲治郎'],
        publishedAt: '2008年03月20日',
      },
      {
        volume: '2',
        startArticle: '32',
        endArticle: '103',
        title: '設立 ⑵',
        path: '/document/kommentar_shojihomu_kaishaho_2',
        authors: ['山下 友信'],
        publishedAt: '2014年03月10日',
      },
      {
        volume: '3',
        startArticle: '104',
        endArticle: '154の2',
        title: '株式 ⑴',
        path: '/document/kommentar_shojihomu_kaishaho_3',
        authors: ['山下 友信'],
        publishedAt: '2013年03月30日',
      },
      {
        volume: '4',
        startArticle: '155',
        endArticle: '198',
        title: '株式 ⑵',
        path: '/document/kommentar_shojihomu_kaishaho_4',
        authors: ['山下 友信'],
        publishedAt: '2009年04月30日',
      },
      {
        volume: '5',
        startArticle: '199',
        endArticle: '235',
        title: '株式 ⑶',
        path: '/document/kommentar_shojihomu_kaishaho_5',
        authors: ['神田 秀樹'],
        publishedAt: '2013年02月28日',
      },
      {
        volume: '6',
        startArticle: '236',
        endArticle: '294',
        title: '新株予約権',
        path: '/document/kommentar_shojihomu_kaishaho_6',
        authors: ['江頭 憲治郎'],
        publishedAt: '2009年10月01日',
      },
      {
        volume: '7',
        startArticle: '295',
        endArticle: '347',
        title: '機関 ⑴',
        path: '/document/kommentar_shojihomu_kaishaho_7',
        authors: ['岩原 紳作'],
        publishedAt: '2013年09月20日',
      },
      {
        volume: '8',
        startArticle: '348',
        endArticle: '395',
        title: '機関 ⑵',
        path: '/document/kommentar_shojihomu_kaishaho_8',
        authors: ['落合 誠一'],
        publishedAt: '2009年02月15日',
      },
      {
        volume: '9',
        startArticle: '396',
        endArticle: '430',
        title: '機関 ⑶',
        path: '/document/kommentar_shojihomu_kaishaho_9',
        authors: ['岩原 紳作'],
        publishedAt: '2014年08月15日',
      },
      {
        volume: '10',
        startArticle: '431',
        endArticle: '444',
        title: '計算等 ⑴',
        path: '/document/kommentar_shojihomu_kaishaho_10',
        authors: ['江頭 憲治郎', '弥永 真生'],
        publishedAt: '2011年10月20日',
      },
      {
        volume: '11',
        startArticle: '445',
        endArticle: '465',
        title: '計算等 ⑵',
        path: '/document/kommentar_shojihomu_kaishaho_11',
        authors: ['森本 滋、弥永 真生'],
        publishedAt: '2010年08月15日',
      },
      {
        volume: '12',
        startArticle: '466',
        endArticle: '509',
        title: '',
        path: '/document/kommentar_shojihomu_kaishaho_12',
        authors: ['落合 誠一'],
        publishedAt: '2009年10月30日',
      },
      {
        volume: '13',
        startArticle: '510',
        endArticle: '574',
        title: '清算 ⑵',
        path: '/document/kommentar_shojihomu_kaishaho_13',
        authors: ['松下 淳一', '山本 和彦'],
        publishedAt: '2014年07月15日',
      },
      {
        volume: '14',
        startArticle: '575',
        endArticle: '613',
        title: '持分会社 ⑴',
        path: '/document/kommentar_shojihomu_kaishaho_14',
        authors: ['神田 秀樹'],
        publishedAt: '2014年03月10日',
      },
      {
        volume: '15',
        startArticle: '614',
        endArticle: '675',
        title: '持分会社 ⑵',
        path: '/document/kommentar_shojihomu_kaishaho_15',
        authors: ['神田 秀樹'],
        publishedAt: '2018年10月20日',
      },
      {
        volume: '16',
        startArticle: '676',
        endArticle: '742',
        title: '社債',
        path: '/document/kommentar_shojihomu_kaishaho_16',
        authors: ['江頭 憲治郎'],
        publishedAt: '2010年10月25日',
      },
      {
        volume: '17',
        startArticle: '743',
        endArticle: '774',
        title: '',
        path: '/document/kommentar_shojihomu_kaishaho_17',
        authors: ['森本 滋'],
        publishedAt: '2010年06月30',
      },
      {
        volume: '18',
        startArticle: '775',
        endArticle: '816',
        title: '',
        path: '/document/kommentar_shojihomu_kaishaho_18',
        authors: ['森本 滋'],
        publishedAt: '2010年12月30日',
      },
      {
        volume: '19',
        startArticle: '817',
        endArticle: '867',
        title: '外国会社・雑則 ⑴',
        path: '/document/kommentar_shojihomu_kaishaho_19',
        authors: ['岩原 紳作'],
        publishedAt: '2021年03月30日',
      },
      {
        volume: '20',
        startArticle: '868',
        endArticle: '938',
        title: '雑則 ⑵',
        path: '/document/kommentar_shojihomu_kaishaho_20',
        authors: ['森本 滋', '山本 克己'],
        publishedAt: '2016年03月30日',
      },
      {
        volume: '21',
        startArticle: '939',
        endArticle: '979',
        title: '雑則 ⑶・罰則',
        path: '/document/kommentar_shojihomu_kaishaho_21',
        authors: ['落合 誠一'],
        publishedAt: '2011年09月30日',
      },
      {
        volume: '補',
        title: '平成26年改正',
        path: '/document/kommentar_shojihomu_kaishaho_sup',
        authors: ['岩原 紳作'],
        publishedAt: '2019年10月20日',
      },
    ];
  }
}
</script>

<style lang="scss" scoped>
@import '@/../node_modules/vuetify/src/styles/styles.sass';

.kommentar-knowledge-panel {
  margin-bottom: 20px;

  ::v-deep > a {
    color: inherit;
  }

  align-items: flex-start;
  border-bottom: 3px solid #ccc;
  padding: 0 0 20px;

  > .title {
    margin-left: -0.6em;
    font-size: 1.6rem !important;
    font-weight: bold;
    font-family: 'Noto Serif JP', serif !important;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      font-size: 1.4rem !important;
    }
  }

  > .lead {
    margin-top: 0.6em;
    font-size: 0.9em;
    color: #555;
  }

  .wrapper {
    width: 100%;
    display: flex;

    > .image {
      width: 105px;
      height: auto;
      margin-right: 16px;

      > img {
        width: 100%;
      }

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        display: none;
      }
    }
    > .content {
      flex-grow: 1;

      .kan-wrapper {
        display: grid;

        grid-template-columns: minmax(250px, 1fr) repeat(auto-fit, minmax(230px, 1fr));
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
        gap: 0.12em 0.8em;

        @media #{map-get($display-breakpoints, 'md-and-down')} {
          grid-template-columns: minmax(240px, 1fr) repeat(auto-fit, minmax(230px, 1fr));
          grid-template-rows: repeat(9, 1fr);
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          grid-template-columns: minmax(240px, 1fr) repeat(auto-fit, minmax(230px, 1fr));
          grid-template-rows: repeat(12, 1fr);
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(24, 1fr);
        }

        .kan {
          // display: flex;
          // justify-content: space-between;
          font-size: 14px;
          color: $primary;
          display: flex;
          border-bottom: 1px solid transparent;

          .kan-title {
            min-width: 11em;
            display: flex;
            align-items: center;

            > span > .volume {
              display: inline-block;
              min-width: 2.2em;
            }

            > .line {
              flex-grow: 1;
              margin: 0 0.4em;
              height: 1px;
              background: lighten($primary, 30%);
            }
          }
          .kan-subject {
          }

          &:hover {
            opacity: 0.8;
            // border-bottom-color: #777;
          }

          small {
            padding-left: 0.15em;
          }
        }
      }
    }
  }
}
</style>
