import { Component, Vue, Prop, State, Watch } from 'nuxt-property-decorator';
import SnippetsEntry from '@/components/snippets-entry.vue';
import { State as MyState } from '@/store';

import SearchResultSnippetsPreviewLink from '@/components/search-result-snippets-preview-link.vue';
import { asyncScrollTo, getKeyFromSeq, snippetKey } from '@/utility';
import { DocumentTypeEnum, SearchHit, SearchResultEntry } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';
import { encode_object } from 'rison';
import { PartialSearchQuery } from '@/types/SearchQuery';
import SNIPPET_MASK from '@/assets/snippet-mask.png';
import { isDateAfterNow } from '@/utils/dateUtil';
import dayjs from 'dayjs';
import { getProductPrice } from '@/utils/shopifyService';

type Excerpt = SearchHit & {
  isPreviewAvailable: boolean;
};

@Component({ components: { SearchResultSnippetsPreviewLink, SnippetsEntry } })
export default class SearchResultSnippets extends Vue {
  snippetKey = snippetKey;

  @Prop()
  entry!: SearchResultEntry;

  /** プレビュー表示が可能な seq の配列。ここに含まれないスニペットはプレビューでは無く直接閲覧ページへリンクする */
  @Prop() previewPageSeqs!: number[] | undefined;

  /** マーケティング用に機能をプレビュー機能を制限する場合 */
  @Prop() hideTextWithAskingContact?: boolean;

  @Prop() storeUrl?: string;

  @State((state: MyState) => state.search.preview) preview!: string | boolean;

  @State((state: MyState) => state.search.selectedSnippet) selectedSnippets!: string | null;

  /** スニペットの表示順 */
  excerptsOrder: 'score' | 'page' = 'score';

  intersectionObserverRefId?: string;

  sectionPointers: (string | undefined)[] = [];

  isShow = false;

  isInview = false;

  /** スニペットがスクロール中に mouseover イベントが発生してプレビュー位置がずれるのを避けるためのフラグ */
  isScrolling = false;

  readonly snippetMask = SNIPPET_MASK;

  productPrice = '';

  async created(): Promise<void> {
    this.generateSectionPointers();
    await this.fetchProductPrice();
  }

  async fetchProductPrice(): Promise<void> {
    try {
      const handle = this.entry.document.id;
      this.productPrice = await getProductPrice(handle);
    } catch (error) {
      console.error('Failed to fetch product price:', error);
    }
  }

  mounted(): void {
    try {
      this.intersectionObserverRefId = this.$registerIntersectionObserver(
        this.$refs['intersection-observer'] as HTMLElement,
        this,
      );
    } catch (error) {
      this.isInview = true;
      console.warn(error);
    }
  }

  beforeDestroy(): void {
    if (this.intersectionObserverRefId) {
      this.$resignIntersectionObserver(this.intersectionObserverRefId);
    }
  }

  showSnippets(): boolean {
    return (this.entry.hits?.length || 0) + (this.entry.attachmentHits?.length || 0) > 0;
  }

  showOnSaleMessage(): boolean {
    return !!(
      !this.entry.document.docAccessible &&
      this.entry.document.purchaseType === 'purchasable' &&
      this.storeUrl &&
      !this.$domain.isMHMMypage
    );
  }

  handleOnSaleMessageClick(): void {
    if (this.storeUrl) {
      this.$telemetry.sendClickTelemetry(
        {
          button: 'search-result-snippets__on-sale-message',
          params: { storeUrl: this.storeUrl, docId: this.entry.document.id },
        },
        this.$route,
      );
    } else {
      this.$telemetry.sendClickTelemetry(
        { button: 'search-result-snippets__on-sale-message', params: { docId: this.entry.document.id } },
        this.$route,
      );
    }
  }

  get onSaleMessage(): string {
    return isDateAfterNow(this.entry.document.availableFrom)
      ? 'Legalscape Store にて予約購入可能です。'
      : 'この書籍は Legalscape Store で購入後、利用可能なコンテンツです。';
  }

  get showReservedMessage(): boolean {
    return !!(
      !this.entry.document.docAccessible &&
      this.entry.document.purchaseType === 'purchasable' &&
      this.entry.document.purchased &&
      this.storeUrl &&
      !this.$domain.isMHMMypage
    );
  }

  get reservedMessage(): string {
    const availableFrom = this.entry.document.availableFrom;
    return isDateAfterNow(availableFrom)
      ? `Legalscape Store にて予約購入済みです。${dayjs(availableFrom).format('YYYY年MM月DD日')}に利用可能になります。`
      : 'Legalscape Store にて予約購入済みです。';
  }

  get orderedExcerpts(): Excerpt[] {
    // UnaccessibleDocRecordTiny に存在しないプロパティにアクセスする前に型アサーションを行う
    // FIXME: そもそも、アクセシブルではない文献が検索に表示されないなら、 SearchResultEntry のスキーマ定義の訂正が必要
    const isPdfFileForDocument =
      isAccessible(this.entry.document) &&
      this.entry.document.pdfFileURI !== null &&
      this.entry.document.pdfFileURI.length > 0;
    const excerpts = this.entry.hits.map((e) => ({
      ...e,
      isPreviewAvailable:
        isPdfFileForDocument && this.previewPageSeqs !== undefined && this.previewPageSeqs.includes(e.pageSeq),
    }));
    switch (this.excerptsOrder) {
      case 'page':
        return excerpts.sort((a, b) => a.pageSeq - b.pageSeq);
      case 'score':
      default:
        return excerpts;
    }
  }

  get attachmentHits(): { attachmentId: string; count: string | null; name: string }[] {
    return this.entry.attachmentHits.map((hit) => {
      let count: string | null = null;
      if (hit.count === 0) {
        count = null;
      } else if (hit.count === '100+') {
        count = '100回以上';
      } else {
        count = `${hit.count}回`;
      }

      return {
        attachmentId: hit.attachment.id,
        count,
        name: hit.attachment.name,
      };
    });
  }

  get searchQuery(): string {
    return this.$route.query.q
      ? encodeURIComponent(this.castQuery(this.$route.query.q))
      : encodeURIComponent(encode_object({ keyword: this.$route.query.os, type: ['book'] } as PartialSearchQuery));
  }

  pageText(excerpt: SearchHit): string {
    // UnaccessibleDocRecordTiny に存在しないプロパティにアクセスする前に型アサーションを行う
    // FIXME: そもそも、アクセシブルではない文献が検索に表示されないなら、 SearchResultEntry のスキーマ定義の訂正が必要
    if (!isAccessible(this.entry.document)) return '';
    const suffix = this.entry.document.type === DocumentTypeEnum.Law ? '' : 'ページ';
    return `${
      this.entry.document.folioPerSeq ? this.entry.document.folioPerSeq[excerpt.pageSeq] : excerpt.pageSeq + 1
    }${suffix}`;
  }

  toggleOrderHandler(): void {
    switch (this.excerptsOrder) {
      case 'page':
        this.excerptsOrder = 'score';
        break;
      case 'score':
        this.excerptsOrder = 'page';
        break;
    }
  }

  onShowPreviewHandler(docId: string, previewSeq: number): void {
    this.$emit('showPreview', docId, previewSeq);
    this.scrollToCurrentSnippet();
  }

  onSnippetMouseenterHandler(docId: string, previewSeq: number, snippetKey: string): void {
    if (this.isScrolling) return;
    if (this.preview !== docId) return;
    if (snippetKey === this.selectedSnippets) return;
    this.$emit('showPreview', docId, previewSeq);
  }

  @Watch('isInview')
  onIsVisibleChangeHandler(isInview: boolean): void {
    if (isInview) {
      this.isShow = true;
    }
  }

  @Watch('orderedExcerpts')
  onOrderChangeHandler(): void {
    this.scrollToCurrentSnippet();
  }

  scrollToCurrentSnippet(): void {
    this.isScrolling = true;
    this.$nextTick(async () => {
      const cursor = this.$el.querySelector('.search-result-snippets-scrollable');
      if (!cursor) return;

      const snippetEntryElements =
        this.$refs[`snippets-entry-${(this.$store.state as MyState).search.selectedSnippet}`];
      const snippetEntryElement = Array.isArray(snippetEntryElements) ? snippetEntryElements[0] : snippetEntryElements;
      if (snippetEntryElement) {
        await (snippetEntryElement as unknown as InstanceType<typeof SnippetsEntry>).asyncScrollTo(cursor);
      } else {
        await asyncScrollTo(cursor, 0);
      }
      this.isScrolling = false;
    });
  }

  /**
   * TEMP: ページ番号から章タイトルを引く
   * @param toc
   * @param seq ページ番号 (0-index)
   */
  generateSectionPointers(): void {
    // UnaccessibleDocRecordTiny に存在しないプロパティにアクセスする前に型アサーションを行う
    // FIXME: そもそも、アクセシブルではない文献が検索に表示されないなら、 SearchResultEntry のスキーマ定義の訂正が必要
    if (!isAccessible(this.entry.document) || this.entry.document.toc === null) return;
    const toc = this.entry.document.toc;
    this.entry.hits.forEach((hit) => {
      const seq = hit.pageSeq;

      let key = getKeyFromSeq(toc, seq);

      if (key === undefined) {
        this.sectionPointers[seq] = undefined;
      } else {
        let html = '';

        while (toc.byKey[key]) {
          html = `<ol><li>${toc.byKey[key].label}${html}</li></ol>`;
          key = toc.byKey[key].parent;
        }

        this.sectionPointers[seq] = html.replace('<ol>', '<ol class="section-pointer">');
      }
    });
  }

  private castQuery(query: string | (string | null)[]): string {
    if (Array.isArray(query)) {
      return query.toString();
    }

    return query;
  }
}
