export async function getProductPrice(handle: string): Promise<string> {
  const query = `
    query getProductPrice($handle: String!) {
      product(handle: $handle) {
        variants(first: 1) {
          edges {
            node {
              price {
                amount
              }
            }
          }
        }
      }
    }
  `;

  const variables = { handle };

  try {
    const response = await fetch(
      `${process.env.SHOPIFY_STOREFRONT_API_URL}/api/${process.env.SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN as string,
        },
        body: JSON.stringify({ query, variables }),
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const price = data.data.product.variants.edges[0]?.node.price.amount;

    return price ? formatPrice(parseFloat(price)) : '';
  } catch (error) {
    console.error('Error fetching product price:', error);
    return '';
  }
}

function formatPrice(price: number): string {
  return `¥${price.toLocaleString('ja-JP', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
}
