import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { PaginatedPage } from '@/types/PaginatedPage';
import { Location } from 'vue-router';
import { encode_object } from 'rison';
import { PartialSearchQuery } from '@/types/SearchQuery';

@Component
export default class Pagination extends Vue {
  @Prop() currentPage!: number;
  @Prop() itemsPerPage!: number;
  @Prop() totalItems!: number;

  /** 検索結果の全ページ数 */
  get totalPages() {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  /**
   * ページ番号から検索クエリを含む URL を導く
   * @param index ページ番号
   */
  buildUrl(index: number): Location {
    if (index <= 0) throw new Error('利用できないページ番号です');
    const query = {
      ...this.$route.query,
      p: String(index),
      q: this.$route.query.q
        ? this.$route.query.q
        : encode_object({ keyword: this.$route.query.os, type: ['book'] } as PartialSearchQuery),
    };
    return { path: this.$route.path, query: query };
  }

  /** 現在のページの前のページ */
  get prevPage(): Location {
    return this.buildUrl(this.currentPage - 1);
  }

  /** 現在のページの次のページ */
  get nextPage(): Location {
    return this.buildUrl(this.currentPage + 1);
  }

  /**
   * 範囲の作成
   * @param from 開始
   * @param to 終了
   */
  oneBasedRange(from: number, to: number): Array<number> {
    const range = [];

    from = from > 0 ? from : 1;

    for (let i = from; i <= to; i++) {
      range.push(i);
    }

    return range;
  }

  mounted() {
    this.init();
  }

  init() {
    this.$nextTick(this.resize);
  }

  /** ウィンドウサイズ的に描画できるボタン数 */
  maxButtons = 0;

  /** ウィンドウリサイズ時に描画できるボタン数を計算し直す */
  resize() {
    const width = this.$el && this.$el.parentElement ? this.$el.parentElement.clientWidth : window.innerWidth;

    // maxButtons = (横幅 - ページネーションボタン * 2) / 要素サイズを値を決め打ちして計算する
    this.maxButtons = Math.floor((width - 90) / 44);
  }

  /** ウィンドウサイズと現在のページ位置から表示するページ番号を決める
   *  (v-pagination から移植)
   */
  get rangeToShow(): Array<number | '...'> {
    /** 見せるボタン数の固定的最大値 */
    const totalVisible = 10000000000;

    /** 実際に表示できるボタン数の最大値 */
    const maxLength = Math.min(
      Math.max(0, totalVisible) || this.totalPages,
      Math.max(0, this.maxButtons) || this.totalPages,
      this.totalPages,
    );

    if (this.totalPages <= maxLength) {
      return this.oneBasedRange(1, this.totalPages);
    }

    const even = maxLength % 2 === 0 ? 1 : 0;
    /** 左端から数えて 表示可能ボタン数/2 の範囲 */
    const left = Math.floor(maxLength / 2);
    /** 右端から数えて 表示可能ボタン数/2 くらいの範囲 */
    const right = this.totalPages - left + 1 + even;

    /** 現在のページの前後をなるべく表示し、途中を '...' で省略する。
     *  省略範囲は left, right に対する現在位置で決める。
     */
    if (this.currentPage > left && this.currentPage < right) {
      const start = this.currentPage - left + 2;
      const end = this.currentPage + left - 2 - even;

      return [1, '...', ...this.oneBasedRange(start, end), '...', this.totalPages];
    } else if (this.currentPage === left) {
      const end = this.currentPage + left - 1 - even;
      return [...this.oneBasedRange(1, end), '...', this.totalPages];
    } else if (this.currentPage === right) {
      const start = this.currentPage - left + 1;
      return [1, '...', ...this.oneBasedRange(start, this.totalPages)];
    } else {
      return [...this.oneBasedRange(1, left), '...', ...this.oneBasedRange(right, this.totalPages)];
    }
  }

  /**
   * ページ番号からレンダリングに必要な情報をまとめる
   * @param index ページ番号
   */
  composeItem(index: number): PaginatedPage {
    return { isCurrent: index == this.currentPage, isDots: false, index: index, url: this.buildUrl(index) };
  }

  /** 描画すべきページたち */
  get pages(): Array<PaginatedPage> {
    return this.rangeToShow.map((i) => {
      return i === '...' ? { isCurrent: false, isDots: true, index: i, url: null } : this.composeItem(i);
    });
  }
}
