<template>
  <div class="preview-container">
    <div ref="pagesWrapper" class="pages-wrapper">
      <div ref="pages" class="pages">
        <div v-for="(pageSeq, i) in pageSeqs" :key="pageSeq">
          <div v-if="isDiscountinuous(i)" class="preview-ellipsis" />
          <div
            ref="pageWrapper"
            class="pdfViewer removePageBorders page-wrapper"
            :style="pageLoaded[i] ? {} : pageWrapperRect"
            :data-index="i"
          >
            <div v-if="!pageLoaded[i]" class="empty-page" :style="pageElementRect">
              <div class="loading-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!initialPageLoaded || isError" class="overlap-wrapper">
      <v-progress-circular v-if="!initialPageLoaded && !isError" indeterminate />
      <loading-error v-if="isError" :href-document="docId" />
    </div>
  </div>
</template>

<script lang="ts">
import Axios from 'axios';
// @ts-expect-error 型定義が存在しないためエラーが出る
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker?worker';
import { debounce } from 'throttle-debounce';
import { PDFSinglePageViewer, EventBus, PDFLinkService, PDFFindController } from 'pdfjs-dist/web/pdf_viewer';
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
import LoadingError from '@/components/loading-error.vue';
import { isAxiosCancelError } from '@/utils/axiosUtis';

declare global {
  interface Window {
    pdfjsWorker: Worker;
  }
}

const scaleMode = 'page-fit';
const downloadPDFRetryCount = 3;

window.pdfjsWorker = pdfjsWorker;

interface Thennable<_> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  then: Function;
}

function pdfPromiseToPromise<T>(promise: Thennable<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    promise.then(resolve, reject);
  });
}

type CSSRect = {
  width?: string;
  height?: string;
};

/**
docId、pageSeqsが変わることは想定していないので、keyを必ず指定すること。
*/
@Component({
  components: {
    LoadingError,
  },
})
export default class Preview extends Vue {
  $refs!: {
    pages: HTMLDivElement;
    pageWrapper: HTMLDivElement[];
    pagesWrapper: HTMLDivElement;
  };

  loadingTasks: pdfjsLib.PDFDocumentLoadingTask[] = []; // 削除時に必要なだけなので順番を気にせずpushしていく

  viewers: PDFSinglePageViewer[] = [];
  linkServices: PDFLinkService[] = [];

  pageWrapperRect: CSSRect = {};
  pageElementRect: CSSRect = {};

  pageLoaded: boolean[] = [];
  isError = false;
  isDestroyed = false;

  readonly axiosCancelSource = Axios.CancelToken.source();

  /** プレビューの各ページを監視するためのオブザーバー */
  observer!: IntersectionObserver;

  /** 現在画面内に表示されている PDF プレビューのインデックス */
  inViewPreviewIndexes: number[] = [];

  /** PDF を順番に取得するためのキュー */
  pdfPreviewLoadingQueue: [number, number][] = [];

  @Prop()
  docId!: string | boolean;

  @Prop()
  pageSeqs!: number[];

  @Prop()
  previewInitialPage!: number | null;

  @Prop()
  keyword?: string;

  get initialPageIndex(): number {
    return this.previewInitialPage !== null &&
      this.previewInitialPage > 0 &&
      this.previewInitialPage <= (this.pageSeqs ?? []).length
      ? this.previewInitialPage - 1
      : 0;
  }

  get initialPageLoaded(): boolean {
    return this.pageLoaded && this.pageLoaded[this.initialPageIndex];
  }

  isDiscountinuous(index: number): boolean {
    if (!this.pageSeqs || !this.pageSeqs.length) {
      return false;
    }

    if (index === 0) {
      return this.pageSeqs[index] !== 0;
    }

    return this.pageSeqs[index] - this.pageSeqs[index - 1] > 1;
  }

  created(): void {
    // TODO: サイズを変えまくると`TextLayer task cancelled.`というエラーが出ることがある
    window.addEventListener('resize', this.updateScale);
  }

  updateScale = debounce(100, () => {
    for (const v of this.viewers) {
      if (v) {
        v.currentScaleValue = scaleMode;
      }
    }
  });

  @Watch('keyword')
  updateHighlight(): void {
    for (const v of this.viewers) {
      if (v) {
        v.findController.executeCommand('find', { query: this.keyword, highlightAll: true });
      }
    }
  }

  async mounted(): Promise<void> {
    const pagesContainer = this.$refs.pages;
    if (!this.pageSeqs || !this.pageSeqs.length || !this.docId || !pagesContainer) {
      return;
    }

    this.viewers = new Array(this.pageSeqs.length);
    this.pageLoaded = new Array(this.pageSeqs.length);
    this.isError = false;

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const target = entry.target;
          if (!(target instanceof HTMLDivElement)) return;
          const { index } = target.dataset;
          if (index === undefined) return;
          if (entry.isIntersecting) {
            this.inViewPreviewIndexes.push(parseInt(index));
          } else {
            this.inViewPreviewIndexes = this.inViewPreviewIndexes.filter((i) => i !== parseInt(index));
          }
        });
      },
      { root: this.$refs['pagesWrapper'] },
    );

    try {
      const initialPageWrapper = this.$refs.pageWrapper[this.initialPageIndex];
      if (!initialPageWrapper) {
        return;
      }

      const initialPageSeq = this.pageSeqs[this.initialPageIndex];

      await this.renderPDF(pagesContainer, initialPageWrapper, initialPageSeq, this.initialPageIndex);

      // 1stページのサイズを取得し、全ページに適用する
      this.pageWrapperRect = this.domRectToCSSRect(await this.waitElementRendered(initialPageWrapper, 10000));
      const initialPageElement = initialPageWrapper.querySelector('.page');
      if (!initialPageElement) {
        // NOTE: 途中でdestroyされた場合などが該当する
        return;
      }
      this.pageElementRect = this.domRectToCSSRect(await this.waitElementRendered(initialPageElement, 10000));

      this.$refs['pageWrapper'].forEach((el) => {
        this.observer.observe(el);
      });

      let leftIndex = this.initialPageIndex - 1;
      let rightIndex = this.initialPageIndex + 1;
      let queue: [number, number][] = [];
      while (0 <= leftIndex || rightIndex < this.pageSeqs.length) {
        if (0 <= leftIndex) {
          queue.push([leftIndex, this.pageSeqs[leftIndex]]);
          leftIndex -= 1;
        }
        if (rightIndex < this.pageSeqs.length) {
          queue.push([rightIndex, this.pageSeqs[rightIndex]]);
          rightIndex += 1;
        }
      }
      this.pdfPreviewLoadingQueue = queue;

      this.registerConsumer();
    } catch (e) {
      console.log(e);
      this.isError = true;
    }
  }

  /** コンシューマを登録して次のキューの消費タスクを登録する */
  registerConsumer(): void {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(this.pdfPreviewQueueConsume, { timeout: 100 });
    } else {
      setTimeout(this.pdfPreviewQueueConsume, 1);
    }
  }

  /** キューから PDF を取得してレンダリングする */
  async pdfPreviewQueueConsume(): Promise<void> {
    this.inViewPreviewIndexes.forEach((index) => this.precedePageSeqInQueueByIndex(index));

    const current = this.pdfPreviewLoadingQueue.shift();
    if (current === undefined) return;
    const [index, pageSeq] = current;

    const pagesContainer = this.$refs.pages;
    const pageWrapper = this.$refs.pageWrapper[index];

    // 読み込みが完了するまでにプレビューを閉じた場合、即終了する
    if (!pageWrapper) {
      return;
    }

    try {
      await this.renderPDF(pagesContainer, pageWrapper, pageSeq, index);
      this.registerConsumer();
    } catch (error) {
      if (isAxiosCancelError(error) && error.message === 'preview component destroyed') {
        console.log(error);
      } else {
        throw error;
      }
    }
  }

  /** 特定の PDF をキューの先頭に移動する */
  precedePageSeqInQueueByIndex(pageSeqIndex: number): void {
    const index = this.pdfPreviewLoadingQueue.findIndex((q) => q[0] === pageSeqIndex);
    if (index === -1) return;
    const priorQueue = this.pdfPreviewLoadingQueue[index];
    this.pdfPreviewLoadingQueue.splice(index, 1);
    this.pdfPreviewLoadingQueue.unshift(priorQueue);
  }

  domRectToCSSRect({ width, height }: DOMRect): CSSRect {
    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  }

  async waitElementRendered(elem: Element, timeout: number): Promise<DOMRect> {
    const interval = 100;
    const tryCount = timeout / interval;
    let rect: DOMRect | undefined = undefined;
    for (let i = 0; i < tryCount; i++) {
      rect = elem.getBoundingClientRect();
      if (rect.width > 0 && rect.height > 0) {
        return rect;
      }
      await new Promise((resolve) => setTimeout(resolve, interval));
    }
    return rect as DOMRect;
  }

  onClickPage(page: number): void {
    this.$router.push({ path: `/document/${this.docId}${location.search}#page=${page + 1}` });
  }

  async downloadPDF(docId: string, pageSeq: number): Promise<pdfjsLib.PDFDocumentProxy> {
    const pdf = await this.$repositories.docs.getSinglePagePdf(docId, pageSeq, {
      retryCount: downloadPDFRetryCount,
      cancelToken: this.axiosCancelSource.token,
    });

    if (this.isDestroyed) {
      throw new Error('component destroyed');
    }

    const task = pdfjsLib.getDocument({
      cMapUrl: '/vendor/pdfjs/cmaps/',
      cMapPacked: true,
      data: pdf,
      httpHeaders: { 'X-Requested-With': 'XMLHttpRequest' },
    }) as pdfjsLib.PDFDocumentLoadingTask;

    this.loadingTasks.push(task);

    return pdfPromiseToPromise(task.promise);
  }

  async renderPDF(
    pagesContainer: HTMLDivElement,
    pageWrapper: HTMLDivElement,
    pageSeq: number,
    index: number,
  ): Promise<void> {
    if (typeof this.docId !== 'string') return;
    const pdf = await this.downloadPDF(this.docId, pageSeq);

    const eventBus = new EventBus();
    eventBus.on<PDFSinglePageViewer>('pagesinit', ({ source }) => {
      source.currentScaleValue = scaleMode;
      source.findController.executeCommand('find', { query: this.keyword, highlightAll: true });
      source.viewer.addEventListener('click', () => this.onClickPage(pageSeq));
      Vue.set(this.pageLoaded, index, true);
    });

    const pdfLinkService = new PDFLinkService({
      eventBus: eventBus,
    });

    const pdfFindController = new PDFFindController({
      eventBus: eventBus,
      linkService: pdfLinkService,
    });
    // 検索ハイライトをPDF.jsの機能を利用して行うが、親切に最初のヒット箇所に飛ぶのを無理やりやめさせる
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    pdfFindController.scrollMatchIntoView = () => {};

    const pdfSinglePageViewer = new PDFSinglePageViewer({
      container: pagesContainer,
      viewer: pageWrapper,
      eventBus: eventBus,
      linkService: pdfLinkService,
      findController: pdfFindController,
      removePageBorders: true,
    });
    // PDFの読み込みが完了したらスクロールするようになっているのを無効化
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (pdfSinglePageViewer as any)._scrollIntoView = () => {};
    pdfLinkService.setViewer(pdfSinglePageViewer);

    Vue.set(this.viewers, index, pdfSinglePageViewer);
    Vue.set(this.linkServices, index, pdfLinkService);

    pdfSinglePageViewer.setDocument(pdf);
    pdfLinkService.setDocument(pdf, null);
  }

  async scrollToIndex(index: number): Promise<void> {
    try {
      await new Promise<void>((resolve, reject) => {
        let remainingRetryCount = 100; // 手元で試したところ 5 ~ 6回目くらいで成功します
        const timer = setInterval(() => {
          if (this.pageElementRect.height !== undefined) {
            clearInterval(timer);
            resolve();
          }
          remainingRetryCount -= 1;
          if (remainingRetryCount <= 0) {
            clearInterval(timer);
            reject('プレビューのスクロールに失敗しました');
          }
        }, 200);
      });

      const pageWrapper = this.$refs.pageWrapper[index];
      if (pageWrapper) {
        pageWrapper.scrollIntoView({ block: 'center' });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async beforeDestroy(): Promise<void> {
    try {
      this.isDestroyed = true;
      window.removeEventListener('resize', this.updateScale);
      this.axiosCancelSource.cancel('preview component destroyed');

      await Promise.all(this.loadingTasks.map((task) => task.destroy()));

      this.viewers.forEach((viewer) => {
        viewer.setDocument(null);
      });
      this.linkServices.forEach((service) => {
        service.setDocument(null);
        service.setViewer(null);
      });
      this.loadingTasks = [];
      this.viewers = [];
      this.linkServices = [];
    } catch (e) {
      console.error(e);
    }
  }
}
</script>

<style lang="scss">
@import '../../node_modules/pdfjs-dist/web/pdf_viewer.css';

.preview-container {
  width: 100%;
  height: 100%;
  position: relative;

  .pages-wrapper {
    padding: 20px;
    background-color: gray;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .pages {
      width: 100%;
      height: 100%;
      background-color: gray;
    }
  }

  .page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .empty-page {
    background-color: white;
    position: relative;
    margin-bottom: 10px;

    .loading-icon {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url('../../node_modules/pdfjs-dist/web/images/loading-icon.gif') center no-repeat;
    }
  }

  .overlap-wrapper {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f1f6;
  }

  .preview-ellipsis::before {
    display: block;
    color: white;
    content: '︙';
    text-align: center;
    font-size: 64px;
    font-family: 'Yu Mincho', serif;
    margin-bottom: 10px;
  }

  .pdfViewer .page {
    cursor: pointer;
  }
}

.preview .highlight.selected {
  background-color: rgb(180, 0, 170);
}
</style>
