import "core-js/modules/es.number.constructor.js";
import { decode_object } from 'rison';
export default defineComponent({
  name: 'PreviewLink',
  props: {
    isPreviewing: {
      type: Boolean,
      required: true
    },
    isPreviewAvailable: {
      type: Boolean,
      required: true
    },
    docId: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    pageText: {
      type: String,
      required: true
    },
    q: {
      type: String,
      required: true
    }
  },
  emits: ['mouseover', 'mouseout', 'showPreview'],
  methods: {
    onClick: function onClick() {
      this.$telemetry.sendClickTelemetry({
        button: 'serp-open_document__page',
        params: {
          decodedSearchQuery: this.$route.query.q ? decode_object(this.$route.query.q) : undefined,
          docId: this.docId,
          page: this.page
        }
      }, this.$route);
    },
    clickPreview: function clickPreview() {
      this.$telemetry.sendClickTelemetry({
        button: 'serp-preview_document__page',
        params: {
          decodedSearchQuery: decode_object(this.$route.query.q),
          docId: this.docId,
          page: this.page
        }
      }, this.$route);
      this.$emit('showPreview');
    }
  }
});